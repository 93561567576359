/**
*
* AttachmentsFilterContainer
*
*/
import React, { Component } from 'react'
import PropTypes from "prop-types"
import { createSelector } from 'reselect'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { domain } from '../../../utils'
import styles from './styles.module.scss'

import {
  selectQ,
} from '../../selectors'

import {
  search,
  searchChanged,

} from '../../actions'

import { makeSelectProjects } from '../../../Projects/selectors'
import { makeSelectTags } from '../../../Tags/selectors'

const allOpt = [{ label: 'Alle', value: '' }]

function toOption ({ label, value }) {
  return <option key={value} value={value}>{label}</option>
}
const renderField = ({ input, label, type, handleSubmit, meta: { touched, error } }) => {
  return (
    <div className='form-group'>
      <label>
        <i className='fa fa-search' />
        {' '}
      </label>
      <input
        {...input}
        className='form-control'
        placeholder={label}
        autoComplete='off'
        type={type || 'text'}
        onChange={(data) => {
          input.onChange(data)
          setTimeout(handleSubmit, 1)
        }}
      />
      {touched && error && <span>{error}</span>}
    </div>
  )
}

renderField.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  meta: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
}

const renderRadio = ({ input, label, handleSubmit, radioValue, on, meta: { touched, error } }) => {
  return (
    <div className='form-group'>
      <div className='checkbox'>
        <label>
          <input
            {...input}
            label=''
            placeholder={label}
            type='radio'
            value={radioValue}
            onChange={(data) => {
              input.onChange(data)
              setTimeout(handleSubmit, 1)
            }}
          />
          {' '}
          {label}
        </label>
        {touched && error && <span>{error}</span>}
      </div>
    </div>
  )
}

renderRadio.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  radioValue: PropTypes.string,
  on: PropTypes.bool,
  meta: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func
}

const renderSelect = ({ input, label, handleSubmit, options, icon, meta: { touched, error, submitting } }) => (
  <div className='form-group'>
    <label className='label-o'>
      <i className={`fa fa-${icon}`} />
      {' '}
    </label>
    <select {...input}
      className='form-select'
      onChange={event => {
        input.onChange(event.target.value)
        setTimeout(handleSubmit)
      }}>
      {options}
    </select>
  </div>
)

renderSelect.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array,
  icon: PropTypes.string,
  meta: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func
}

class AttachmentsFilterContainer extends Component { // eslint-disable-line react/prefer-stateless-function

  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    search: PropTypes.func.isRequired,
    searchChanged: PropTypes.func.isRequired,
    initialized: PropTypes.bool
  }

  componentDidMount () {
    this.props.searchChanged({ folder: domain(), content_type_in: this.props.contentType })
  }

  render () {
    const {
      search,
      handleSubmit,
      mediaList,
      tags,
      contentType,
      folder,
      projects,
      projectId
    } = this.props

    // const fs = this.props.getFormState()
    // debugger

    return (
      <form onSubmit={handleSubmit(search)} className={styles.AttachmentsFilterForm}>
        <div className='w-100'>
          <Field
            label='Titel oder Dateiname'
            component={renderField} mediaList
            handleSubmit={handleSubmit(search)}
            name='file_or_title_de_cont'
            autoComplete='off'
            className='w-100'
          />
        </div>
        <Field
          name='media_list'
          label='Type'
          icon='file-o'
          placeholder='Select Tag'
          component={renderSelect}
          selectValue={mediaList}
          handleSubmit={handleSubmit(search)}
          options={allOpt.concat(tags).map(toOption)}
        />
        <Field
          name='project_id'
          label='Type'
          icon='file-o'
          placeholder='Select Project'
          component={renderSelect}
          selectValue={projectId}
          handleSubmit={handleSubmit(search)}
          options={allOpt.concat(projects).map(toOption) || []}
        />

        {' '}
        <Field
          name='folder'
          label='Folder'
          icon='folder-o'
          ref='folder'
          placeholder='Select Domain'
          component={renderSelect}
          selectValue={folder}
          handleSubmit={handleSubmit(search)}
          options={[
            { label: 'Alle', value: '' },
            { label: 'doublestandards', value: 'doublestandards' },
            { label: 'livingstandards', value: 'livingstandards' },
            { label: 'findings', value: 'findings' }
          ].map(toOption)}
        />
      </form>
    )
  }
}

const mapStateToProps = createSelector(
  selectQ(),
  makeSelectTags(),
  makeSelectProjects(),
  (q, tags, projects) => ({
    tags: tags.map((t) => ({ label: t.name, value: t.name })),
    mediaList: q.media_list,
    folder: q.folder,
    contentType: q.content_type_in,
    projectId: q.project_id,
    projects: projects.map((p) => ({ label: p.name, value: p.id })),
    initialValues: { ...q },
  })
)

const mapDispatchToProps = (dispatch) => {
  return {
    searchChanged: (values) => dispatch(searchChanged(values)),
    search: (values, typeahead) => {
      dispatch(search(values, typeahead))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'attachmentFilters'  // a unique identifier for this form
  })(AttachmentsFilterContainer)
)
