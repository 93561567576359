import {
  combineReducers,
  applyMiddleware,
  compose
} from 'redux'
import { configureStore } from '@reduxjs/toolkit'
// import thunk from 'redux-thunk'
import { createLogger } from 'redux-logger'
import { reducer as formReducer } from 'redux-form'
import createSagaMiddleware from 'redux-saga'

import attachmentsReducer from './reducer'
import { initAttachments } from './actions'

import sagas from './sagas'

import tags from '../Tags/reducer'
import tagsSagas from '../Tags/sagas'
import { initTags, fetchTags } from '../Tags/actions'

import projects from '../Projects/reducer'
import projectSagas from '../Projects/sagas'
import { initProjects, fetchProjects } from '../Projects/actions'
import {
  ATTACHMENTS_ADD_FILE,
  ATTACHMENTS_UPLOAD_ENQUEUED,
  ATTACHMENTS_UPLOAD_REQUEST,
  ATTACHMENTS_UPLOAD_SUCCESS,
  ATTACHMENTS_UPLOAD_FAILED,
  ATTACHMENTS_UPLOAD_CANCEL,
  ATTACHMENTS_UPLOAD_CANCELED,
  ATTACHMENTS_MAX_PARALLEL_UPLOADS,
  ATTACHMENTS_ADD_PREVIEW,
  ATTACHMENT_GENERATE_PREVIEW,
  ATTACHMENTS_SEARCH
} from './constants'

const logger = createLogger({
  level: 'info',
  collapsed: true,
})

const rootReducer = combineReducers({
  attachments: attachmentsReducer,
  tags,
  projects,
  form: formReducer
})

// create the saga middleware
const sagaMiddleware = createSagaMiddleware()

const enhancer = compose(
  false && process.env.NODE_ENV === 'development'
    ? applyMiddleware(logger, sagaMiddleware)
    : applyMiddleware(sagaMiddleware),
  window.devToolsExtension ? window.devToolsExtension() : noop => noop
)

export default function initStore (initData, railsContext) {
  const {
    attachments,
    total,
    perPage,
    collections
  } = initData
  let state = rootReducer(undefined, initAttachments(attachments, total, perPage, collections))
  state = rootReducer(state, initTags())
  state = rootReducer(state, initProjects())
  const store = configureStore({
    reducer: rootReducer, preloadedState: state, enhancers: [enhancer],
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({

        serializableCheck: {
          ignoredActions: [
            ATTACHMENTS_ADD_FILE,
            ATTACHMENTS_UPLOAD_ENQUEUED,
            ATTACHMENTS_UPLOAD_REQUEST,
            ATTACHMENTS_UPLOAD_SUCCESS,
            ATTACHMENTS_UPLOAD_FAILED,
            ATTACHMENTS_UPLOAD_CANCEL,
            ATTACHMENTS_UPLOAD_CANCELED,
            ATTACHMENTS_MAX_PARALLEL_UPLOADS,
            ATTACHMENTS_ADD_PREVIEW,
            ATTACHMENT_GENERATE_PREVIEW,
            ATTACHMENTS_SEARCH
          ],
          ignoredPaths: ['attachments.attachments.0.cancel'],
        },
      }),
  })

  // run all default exported sagas
  sagas.map(sagaMiddleware.run)
  tagsSagas.map(sagaMiddleware.run)
  projectSagas.map(sagaMiddleware.run)

  store.dispatch(fetchTags())
  store.dispatch(fetchProjects())


  if (module.hot) {
    module.hot.accept('./reducer', () =>
      store.replaceReducer(require('./reducer')) // eslint-disable-line global-require
    )
  }
  return store
}
