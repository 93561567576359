import { put, call, takeLatest } from 'redux-saga/effects'
import {
  TAGS_FETCH_REQUEST,
  TAGS_FETCH_SUCCESS,
  TAGS_FETCH_FAILED
} from './constants'

import { fetchTags } from './api'

// worker Saga: will be fired on ATTACHMENTS_FETCH_REQUESTED actions
function* fetchTagsWorker (action) {
  try {
    const { data } = yield call(fetchTags, action.page)
    yield put({ type: TAGS_FETCH_SUCCESS, ...data })
  } catch (e) {
    yield put({ type: TAGS_FETCH_FAILED, message: e.message })
  }
}
/*
  fetchSaga

  Does not allow concurrent fetches of attachments
*/
export function* fetchSaga () {
  yield takeLatest(TAGS_FETCH_REQUEST, fetchTagsWorker)
}

export default [
  fetchSaga
]
