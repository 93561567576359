import React, { Component } from 'react'
import PropTypes from "prop-types"
import TokenInput, { Option as ComboboxOption } from 'react-tokeninput'
import { without, uniq } from 'lodash'
import Loader from '../components/Loader'
import './styles.scss'

const tagType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
})

class TokenInputApp extends Component {
  static propTypes = {
    inputName: PropTypes.string.isRequired,
    tagList: PropTypes.arrayOf(tagType),
    allTags: PropTypes.arrayOf(tagType).isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      input: '',
      loading: false,
      selected: props.tagList || [],
      options: props.allTags
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleInput = this.handleInput.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
    this.handleRemove = this.handleRemove.bind(this)
  }

  handleChange (value) {
    this.setState({
      selected: value
    })
  }

  handleRemove (value) {
    var selectedOptions = uniq(without(this.state.selected, value))
    this.handleChange(selectedOptions)
  }

  handleSelect (value, combobox) {
    if (typeof value === 'string') {
      value = { id: value, name: value }
    }

    var selected = uniq(this.state.selected.concat([value]))
    this.setState({
      selected: selected,
      selectedToken: null
    })

    this.handleChange(selected)
  }

  handleInput (userInput) {
    this.setState({
      input: userInput,
      loading: true,
      options: []
    })

    setTimeout(function () {
      this.filterTags(this.state.input)
      this.setState({
        loading: false
      })
    }.bind(this), 0)
  }

  filterTags (userInput) {
    if (userInput === '') {
      return this.setState({ options: [] })
    }
    const filter = new RegExp(`^${userInput}`, 'i')
    const filteredNames = this.props.allTags.filter((state) => {
      return filter.test(state.name) // || filter.test(state.id)
    }).filter((state) => {
      return this.state.selected
        .map((value) => value.name)
        .indexOf(state.name) === -1
    })
    this.setState({
      options: filteredNames
    })
  }

  renderComboboxOptions () {
    return this.state.options.map(function (name) {
      return (
        <ComboboxOption
          key={name.id}
          value={name}
        >{name.name}</ComboboxOption>
      )
    })
  }

  render () {
    const selectedTags = this.state.selected.map((tag) => tag.name)

    const options = this.state.options.length
      ? this.renderComboboxOptions()
      : []

    const { inputName } = this.props

    return (
      <div>
        <TokenInput
          isLoading={this.state.loading}
          loadingComponent={<Loader />}
          menuContent={options}
          onChange={this.handleChange}
          onInput={this.handleInput}
          onSelect={this.handleSelect}
          onRemove={this.handleRemove}
          selected={this.state.selected}
          placeholder='Tags hier eingeben'
        />
        <input type='hidden' name={inputName} value={selectedTags} />
      </div>
    )
  }
}

export default TokenInputApp
