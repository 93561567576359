import React, {Component } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
// import TinyMCE from 'react-tinymce'
import { domain } from '../../utils'
import reactTriggerChange from 'react-trigger-change'

const editorConfig = () => ({
  branding: false,
  // toolbar: 'undo redo | styleselect |  bold italic | imgbrowserbtn media link table hr |' +
  //           '  alignleft aligncenter alignright alignjustify | bullist numlist | code removeformat',
  toolbar: toolbar(),
  // plugins: ['image link table lists hr media', 'code'],
  plugins: ['link lists hr', 'code', 'autoresize', 'paste'],
  menubar: false,
  paste_as_text: true,
  statusbar: true,
  convert_urls: false,
  forced_root_block: false,
  plugin_preview_height: 800,
  plugin_preview_width: 1000,
  image_advtab: true,
  style_formats: [
    { title: 'Big', inline: 'span', classes: 'text-big' },
    { title: 'Large', inline: 'span', classes: 'text-large' },
    { title: 'Medium', inline: 'span', classes: 'text-medium' },
    { title: 'Akzent', inline: 'span', classes: 'accent' },
    { title: 'Serif', inline: 'span', classes: 'serif' },
    { title: 'Paragraph', block: 'p' },
    { title: 'Headline 1', block: 'h1' },
    { title: 'Headline 2', block: 'h2' },
    { title: 'Headline 3', block: 'h3' },
  ],
  content_css: getTinyMCECss(),
})

const getTinyMCECss = () => $.map($('[rel=tinymce]'), (lnk) => $(lnk).attr('href'))

const toolbar = () => {
  if (domain() === 'livingstandards') {
    return 'styleselect | bold italic | link hr |  ' +
            ' code | removeformat undo redo'
  } else {
    return 'styleselect |  bold italic | link hr | ' +
            ' alignleft aligncenter alignright | bullist | code | removeformat undo redo'
  }
}

const initTinyMCE = (e, changeCB, blurCB) => (
  tinyMCE.init(Object.assign(editorConfig(), {
    target: e,
    setup: (editor) => {
      editor.on('change', (e) => {
        changeCB(e, editor.getContent())
      })

      editor.on('blur', (e) => {
        blurCB && blurCB(e, editor.getContent())
      })
    }
  }))
)

class RichTextArea extends Component {

  constructor(props) {
    super(props)
    this.getEditorContent = this.getEditorContent.bind(this)
  }

  componentDidMount() {
    const {
      // handleSubmit,
      name
    } = this.props

    const e = document.getElementById(name)
    // debugger
    initTinyMCE(e, (ev, v) => {
      reactTriggerChange(e)
    })
  }

  componentWillUnmount() {
    tinyMCE.get(this.props.name).remove()
  }

  getEditorContent() {
    return tinyMCE.get(this.props.name).getContent()
  }

  renderTinyMCE(field) {
    let props = Object.assign({}, field)
    delete props.input
    delete props.meta
    delete props.config

    return (
      <textarea
        {...field.input}
        id={field.input.name}
        name={field.input.name}
        value={field.input.value || ''}
        onBlur={(event) => {
          field.input.onChange(field.getEditorContent())
          setTimeout(field.handleSubmit)
        }}
        onChange={(event) => {
          field.input.onChange(field.getEditorContent())
          setTimeout(field.handleSubmit)
        }}
      />
    )
  }

  render() {
    const {
      handleSubmit,
      value,
      name
    } = this.props

    return (
      <Field
        getEditorContent={this.getEditorContent}
        component={this.renderTinyMCE}
        name={name}
        ref={name}
        id={name}
        autoComplete='off'
        value={value}
        handleSubmit={handleSubmit}
      />
    )
  }

}

RichTextArea.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  name: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired
}

export default RichTextArea
