import React from 'react'
import PropTypes from "prop-types"
import { connect } from 'react-redux'
import { Card } from '../../Attachments/components/AttachmentCollection/Card'
import AttachmentPreview from '../../Attachments/components/AttachmentPreview'
import {
  moveGalleryItem,
  removeGalleryItem
} from '../actions'

const GalleryForm = ({
  node,
  moveItem,
  removeItem,
  onClickGallery
}) => (
  <div className='form-group'>
    <button type='button' className='btn btn-default btn-select-gallery-images' onClick={onClickGallery}>
      <i className='fa fa-image' />
      {' '}
      Bilder wählen
    </button>
    <div>
      {node.attachments.map((attachment, i) => {
        return (
          <Card
            key={attachment.id}
            index={i}
            id={attachment.id}
            moveCard={moveItem}
          >
            <div style={{ height: 50, width: 50 }}>
              <AttachmentPreview {...attachment} />
            </div>
            <a
              onClick={() => removeItem(attachment.id)}
              style={{ float: 'right', marginTop: -35 }}
            >
              <i className='fa fa-trash-o' />
            </a>
          </Card>
        )
      })}
    </div>
  </div>
)

GalleryForm.propTypes = {
  onClickGallery: PropTypes.func.isRequired,
  node: PropTypes.object.isRequired,
  moveItem: PropTypes.func.isRequired,
  removeItem: PropTypes.func.isRequired,
}

const mapDispatchToProps = (dispatch) => ({
  moveItem: (nodeId, b, c) => dispatch(moveGalleryItem(nodeId, b, c)),
  removeItem: (nid, aid) => dispatch(removeGalleryItem(nid, aid)),
})

export default connect(null, mapDispatchToProps)(GalleryForm)
