import { createSelector } from 'reselect'

const selectTags = () => (state) => state.tags

const makeSelectTags = (id) => createSelector(
  selectTags(),
  (tags) => tags.tags
)

const makeSelectTag = (id) => createSelector(
  selectTags(),
  (state) => state.tags.find((a) => a.id === id)
)

export {
  selectTags,
  makeSelectTags,
  makeSelectTag
}
