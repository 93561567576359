import ReactOnRails from 'react-on-rails';
import attachmentsStore from '../bundles/Attachments/store'
import pageStore from '../bundles/PageBuilder/store'
// import "@hotwired/turbo-rails"
// // Turbo.session.drive = false
// window.Turbo = Turbo

import {
  AttachmentChooser,
  AttachmentsApp,
  AttachmentCollectionChooser,
  TokenInput,
  PageBuilder
} from '../bundles/apps';


// ReactOnRails.setOptions({ turbo: true })

// This is how react_on_rails can see the HelloWorld in the browser.
ReactOnRails.register({
  AttachmentsApp,
  AttachmentChooser,
  AttachmentCollectionChooser,
  TokenInput,
  PageBuilder
});


console.log({ pageStore });
console.log({ attachmentsStore });
//
ReactOnRails.registerStore({
  //
  attachmentsStore,
  pageStore
});

