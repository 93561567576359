import {
  TAGS_FETCH_REQUEST,
  TAGS_FETCH_SUCCESS,
  TAGS_FETCH_FAILED,
} from './constants'

import {setPropArray} from '../utils'

const initialState = {
  complete: false,
  submitting: false,
  err: null,
  usage: {},
  page: 1,
  total: 0,
  perPage: 30,
  q: {},
  tags: []
}

function reducer (state = initialState, action) {
  switch (action.type) {
    case TAGS_FETCH_REQUEST:
      return {
        ...state,
        page: action.page,
        q: action.q,
        tags: setPropArray(state.tags, 'dirty', true),
        complete: false,
        submitting: true,
      }
    case TAGS_FETCH_SUCCESS:
      return {
        ...state,
        tags: state.tags.concat(action.tags).filter((a) => a.dirty !== true),
        complete: true,
        submitting: false,
        total: action.total,
        perPage: action.perPage,
        err: null,
      }
    case TAGS_FETCH_FAILED:
      return {
        ...state,
        complete: false,
        submitting: false,
        err: action.err
      }
    default:
      return state
  }
}

export default reducer
