import React, { Component } from 'react'
import PropTypes from "prop-types"
import styles from './styles.module.scss'
// import loadImage from 'blueimp-load-image/js'

const iconClass = ({
  videoUrl,
  fileName,
  fileContentType
}) => {
  // video
  const videoUrl_ = String(videoUrl)
  if (/vimeo/.test(videoUrl_)) {
    return 'fa-vimeo'
  } else if (/youtube/.test(videoUrl_)) {
    return 'fa-youtube'
  } else if (/facebook/.test(videoUrl_)) {
    return 'fa-facebook'
  }

  // files
  const fileName_ = String(fileName)
  if (/\.pdf$/.test(fileName_)) {
    return 'fa-file-pdf-o'
  } else if (/\.zip$/.test(fileName_)) {
    return 'fa-file-zip-o'
  } else if (!/^image/.test(String(fileContentType))) {
    return 'fa-file'
  }
  return 'hidden'
}

class AttachmentPreview extends Component {
  // componentDidMount() {
  //   const { thumbUrl } = this.props
  //   // console.log("this.thumbUrl", thumbUrl)
  //   if (String(thumbUrl).substr(0, 4) === 'blob') {
  //     console.log('===> thumbUrl', thumbUrl)
  //     loadImage(
  //       thumbUrl,
  //       (img) => { this.refs.img.src = img.src },
  //       {
  //         orientation: true,
  //         maxWidth: 250,
  //         maxHeight: 250,
  //       }
  //     )
  //   }
  // }

  render () {
    const { thumbUrl, url, progress } = this.props
    return (
      <div className={`${styles.AttachmentPreview} attachment-preview`}>
        <div className='icon preview-icon'>
          <i className={`fa ${iconClass(this.props)}`} />
        </div>
        <div className='image-wrap'>
          {(thumbUrl || url) && <img src={thumbUrl || url} ref='img' />}
        </div>
        <div
          className='progressBar'
          style={{
            width: `${progress * 100}%`,
            opacity: progress && progress < 1 ? 1 : 0
          }} />
      </div>
    )
  }
}

AttachmentPreview.propTypes = {
  id: PropTypes.number.isRequired,
  thumbUrl: PropTypes.string,
  videoUrl: PropTypes.string,
  fileName: PropTypes.string.isRequired,
  fileContentType: PropTypes.string.isRequired,
  progress: PropTypes.number,
}

export default AttachmentPreview
