/**
*
* Attachment
*
*/

import React, { Component } from 'react'
import PropTypes from "prop-types"
import classnames from 'classnames'
import AttachmentPreview from '../AttachmentPreview'
import styles from './Attachment.module.scss'
import { formatBytes } from '../../../utils'


const fileType = ({
  videoUrl,
  fileContentType
}) => {
  // return 'image'
  if (videoUrl !== null && String(videoUrl).trim() !== '') {
    return 'video'
  } else if (/pdf/.test(fileContentType)) {
    return 'pdf'
  } else if (/zip/.test(fileContentType)) {
    return 'zip'
  } else if (!/^image/.test(fileContentType)) {
    return 'file'
  }
  return 'image'
}

class Attachment extends Component { // eslint-disable-line react/prefer-stateless-function
  static propTypes = {
    inSelection: PropTypes.bool,
    isSelected: PropTypes.bool,
    progress: PropTypes.number,
    onClick: PropTypes.func,
    onClickEdit: PropTypes.func,
    onClickDelete: PropTypes.func,
    id: PropTypes.number.isRequired,
    title: PropTypes.string,
    titleDe: PropTypes.string,
    titleEn: PropTypes.string,
    fileName: PropTypes.string.isRequired,
    fileContentType: PropTypes.string.isRequired,
    thumbUrl: PropTypes.string,
    videoUrl: PropTypes.string,
    editable: PropTypes.bool,
    dirty: PropTypes.bool,
    delete: PropTypes.func
  }

  constructor(props) {
    super(props)
    this.onClick = () => { this.onClick() }
    this.onClickEdit = () => { this._onClickEdit() }
    this.confirmDelete = () => { this._confirmDelete() }
  }

  _confirmDelete () {
    if (this.props.id < 0) {
      this.props.onClickDelete()
    } else if (confirm('Sind Sie sicher?')) {
      this.props.onClickDelete()
    }
  }

  _onClickEdit () {
    this.props.onClick()
  }

  _onClick () {
    this.props.onClick()
  }

  renderDeleteLink () {
    return (
      <a onClick={this.confirmDelete}>
        {this.props.id < 0
          ? 'Cancel'
          : <i className='fa fa-trash-o' />
        }
      </a>
    )
  }

  renderEditLink () {
    return (
      <a onClick={this.onClickEdit}>
        Edit
      </a>
    )
  }

  render () {
    const cls = classnames(
      styles.attachment,
      'attachment',
      'grid-item',
      fileType(this.props),
      {
        [styles.inSelection]: this.props.inSelection,
        [styles.isSelected]: this.props.isSelected,
        [styles.dirty]: this.props.dirty
      }
    )

    const {
      title,
      fileName,
      fileSize,
      fileHeight,
      fileWidth
    } = this.props

    let links = null
    if (this.props.id < 0 || this.props.editable) {
      links = this.renderDeleteLink()
    }

    return (
      <div className={cls}>
        <div className='panel panel-default'>
          <a onClick={() => this.props.onClick()}>
            <AttachmentPreview {...this.props} />
          </a>
          <div className='panel-footer'>
            <div className='props title'>{title || "untitled"}&nbsp;</div>
            <div className='props'>{fileName}</div>
            <div className='props prop size'>
              <div>{fileSize && formatBytes(fileSize)}</div>
              <div>{(fileHeight && fileWidth) && `${fileWidth}x${fileHeight}`}</div>
            </div>
            <div className='links'>
              {links}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Attachment
