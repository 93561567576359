import _client from './client'
export const client = _client

export const empty = (str) => str === undefined || str === null || String(str).trim() === ''

export const domain = () => document.getElementsByTagName('body')[0].getAttribute('data-domain')

export const underscore = (s) => (s.replace(/\.?([A-Z]+)/g, (x, y) => `_${y.toLowerCase()}`).replace(/^_/, ''))

export const setPropArray = (list, prop, value) => (
  list.map((item) => {
    item[prop] = value
    return item
  })
)


export function formatBytes (bytes, decimals) {
  if (bytes == 0) return '0 Bytes';
  var k = 1024,
    dm = decimals || 2,
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}
