// import { } from 'redux-saga'
import { put, call, select, fork, take, cancel, takeLatest, takeEvery, delay } from 'redux-saga/effects'
import { underscore } from '../../utils'
import {
  PAGE_ADD_NODE,
  PAGE_UPDATE_NODE_IMAGE,
  PAGE_SAVE_REQUEST,
  PAGE_TOGGLE_NODE,
  PAGE_REQUEST_TOGGLE_NODE,
  PAGE_REMOVE_SELECTED_NODE,
  PAGE_UPDATE_NODE_GALLERY,
  PAGE_SHOW_ATTACHMENTS_CHOOSER,
  PAGE_DID_CHOOSE_ATTACHMENTS,
  NodeTypes
} from '../constants'

import {
  deselect,
  updateNode,
  toggleNode,
  saveFailed,
  saveSuccess,
  removeNode,
  updateNodeImage,
  updateNodeGallery,
} from '../actions'

import {
  loadPage as loadAttachments,
  setMultiSelect as setMultiSelectAttachments,
  setSelection as setAttachmentsSelection,
} from '../../Attachments/actions'

import {
  selectedAttachment,
  selectAttachmentsSelection
} from '../../Attachments/selectors'

import {
  selectPageId,
  selectNodes,
  selectDeletedIds,
  selectSelectedNode,
  selectSelectedId,
  selectNode
} from '../selectors'

import { save as saveNodes } from '../api'

// worker Saga: will be fired on PAGE_UPDATE_NODE_IMAGE actions
function* updateNodeImageWorker (action) {
  // select attachment
  const attachment = yield select(selectedAttachment())
  const node_ = yield select(selectSelectedNode())

  const node = {
    id: action.id,
    attachmentId: attachment.id,
    thumbUrl: attachment.thumbUrl,
    videoUrl: attachment.videoUrl
  }

  if (node_.title === undefined) {
    node.title = attachment.title
  }

  yield put(updateNode(node))
}

function* updateNodeGalleryWorker (action) {
  // select attachment
  const attachments = yield select(selectAttachmentsSelection())

  const node = {
    id: action.id,
    attachments
  }

  yield put(updateNode(node))
}

function* addNodeWorker ({ node }) {
  yield put(toggleNode(node.id))
}

function* saveWorker (action) {
  yield put(deselect())

  const pageId = yield select(selectPageId())
  const nodes = yield select(selectNodes())
  const deletedIds = yield select(selectDeletedIds())

  let nodesAttributes = []

  nodes.forEach((node, i) => {
    // let snode = {
    //   nodeType: underscore(node.nodeType),
    //   attachmentId: node.attachmentId,
    //   position: i,
    //   title: node.title,
    //   subtitle: node.subtitle,
    //   caption1: node.caption1,
    //   caption2: node.caption2,
    //   caption3: node.caption3,
    //   date: node.date,
    //   backgroundVideo: node.backgroundVideo,
    //   body: node.body,
    //   right: node.right,
    //   width: node.width,
    //   fancybox: node.fancybox,
    //   clear: node.clear,
    //   darkTitle: node.darkTitle,
    //   videoUrl: node.videoUrl,
    //   borderBottom: node.borderBottom,
    //   fullWidth: node.fullWidth,
    //   twoCols: node.twoCols,
    //   tagList1Context: node.tagList1Context,
    //   tagList2Context: node.tagList2Context,
    //   hero: node.hero,
    // }
    let snode = Object.assign({},
      node,
      {
        nodeType: underscore(node.nodeType),
        position: i,
      })

    if (typeof node.link === 'string') {
      const link = node.link.split(':')
      snode.linkableType = link[0]
      snode.linkableId = link[1]
    } else {
      snode.linkableType = null
      snode.linkableId = null
    }

    if (node.nodeType === NodeTypes.List) {
      snode.perPage = Number(node.perPage) === 0 ? 24 : node.perPage
    }

    if (node.nodeType === NodeTypes.Gallery) {
      snode.attachmentIdsWithPosition = node.attachments.map((a) => a.id)
    }

    if (node.id > 0) {
      snode.id = node.id
    } else {
      delete snode.id
    }

    // remove fields not stored in DB
    ['thumbUrl', 'link', 'attachments'].forEach((key) => delete snode[key])

    // const fNode = [Object.keys(snode).forEach((key) =>
    //   (empty(snode[key])) && delete snode[key]), snode][1]

    nodesAttributes.push(snode)
  })

  nodesAttributes = nodesAttributes.concat(deletedIds.map((id) => ({
    id, _destroy: true
  })))

  try {
    const { data } = yield call(saveNodes, pageId, { page: { nodesAttributes } })
    yield put(saveSuccess(data.nodes))
  } catch (e) {
    yield put(saveFailed(e))
  }
}

function* removeSelectedNodeWorker () {
  const selectedId = yield select(selectSelectedId())
  yield put(removeNode(selectedId))
}

function* toggleWorker (action) {
  const node = yield select(selectSelectedNode())
  const nextNode = yield select(selectNode(action.id))
  if (node && node.nodeType === NodeTypes.Text && nextNode.nodeType !== NodeTypes.Text) {
    yield put(toggleNode(node.id))
    yield delay(1)
  }
  yield put({
    ...action,
    type: PAGE_TOGGLE_NODE
  })
}

const PAGE_DID_CHOOSE_ATTACHMENTS_REDCATOR = 'PAGE_DID_CHOOSE_ATTACHMENTS_REDCATOR'

function attachmentChooserWorkerEl (cb_) {
  var cb = cb_
  return function* _attachmentChooserWorkerEl () {
    // wait for chooser to finish
    yield take(PAGE_DID_CHOOSE_ATTACHMENTS_REDCATOR)
    //
    const attachment = yield select(selectedAttachment())
    cb(attachment)
  }
}

//
var tinyMCETask

function* attachmentChooserWorker (action) {
  yield put(loadAttachments())
  const node = yield select(selectSelectedNode())
  switch (node.nodeType) {
    case NodeTypes.Image:
      yield put(setMultiSelectAttachments(false))
      break
    case NodeTypes.Text:
      yield put(setMultiSelectAttachments(false))
      // fork tinyMCETask
      tinyMCETask = yield fork(attachmentChooserWorkerEl(action.cb))
      break
    case NodeTypes.Gallery:
      yield put(setMultiSelectAttachments(true, true)) // multi, append
      yield put(setAttachmentsSelection(node.attachments))
      break
  }
  $(`#${action.modalId}`).modal('show')
}

function* didChooseAttachmentsWorker (action) {
  const node = yield select(selectSelectedNode())
  switch (node.nodeType) {
    case NodeTypes.Image:
    case NodeTypes.Logo:
      yield put(updateNodeImage(node.id))
      break
    case NodeTypes.Text:
      // run the _attachmentChooserWorkerEl
      yield put({ type: PAGE_DID_CHOOSE_ATTACHMENTS_REDCATOR })
      // cancel the task
      yield cancel(tinyMCETask)
      break
    case NodeTypes.Gallery:
      yield put(updateNodeGallery(node.id))
      break
  }
  $(`#${action.modalId}`).modal('hide')
}

export function* removeSelectedNodeSaga () {
  yield takeLatest(PAGE_REMOVE_SELECTED_NODE, removeSelectedNodeWorker)
}
/*
  updateNodeImageSaga
  Does not allow concurrent searches of attachments
*/
export function* updateNodeImageSaga () {
  yield takeEvery(PAGE_UPDATE_NODE_IMAGE, updateNodeImageWorker)
}

export function* updateNodeGallerySaga () {
  yield takeEvery(PAGE_UPDATE_NODE_GALLERY, updateNodeGalleryWorker)
}

/*
  updateNodeImageSaga
  Does not allow concurrent searches of attachments
*/
export function* addNodeSaga () {
  yield takeEvery(PAGE_ADD_NODE, addNodeWorker)
}

export function* saveSaga () {
  yield takeLatest(PAGE_SAVE_REQUEST, saveWorker)
}

export function* toggleNodeSaga () {
  yield takeEvery(PAGE_REQUEST_TOGGLE_NODE, toggleWorker)
}

export function* attachmentChooserSaga () {
  yield takeLatest(PAGE_SHOW_ATTACHMENTS_CHOOSER, attachmentChooserWorker)
}

export function* didChooseAttachmentsSaga () {
  yield takeLatest(PAGE_DID_CHOOSE_ATTACHMENTS, didChooseAttachmentsWorker)
}

export default [
  updateNodeImageSaga,
  updateNodeGallerySaga,
  addNodeSaga,
  saveSaga,
  removeSelectedNodeSaga,
  toggleNodeSaga,
  attachmentChooserSaga,
  didChooseAttachmentsSaga
]
