import React, { Component } from 'react'
import PropTypes from "prop-types"
import Select from 'react-select'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { createSelector } from 'reselect'
import { domain } from '../../utils'
import AttachmentChooser from '../../Attachments/containers/AttachmentChooser'
import ImageForm from './ImageForm'
import GalleryForm from './GalleryForm'
import RichTextArea from '../components/RichTextArea'

import {
  updateNode,
  updateNodeBody,
  removeSelectedNode,
  showAttachmentChooser,
  didChooseAttachments
} from '../actions'

import {
  selectSelectedNode,
  selectLinks,
} from '../selectors'

import { makeSelectTags } from '../../Tags/selectors'

import { NodeTypes } from '../constants'

import './styles.module.scss'

const modalId = 'NodeForm-modal'

const objId = (node) => `node${node.id}`

const renderCheckbox = ({ input, label, type, handleSubmit, meta: { touched, error } }) => {
  return (
    <div className='form-group'>
      <div className='checkbox'>
        <label>
          <input {...input}
            label=''
            placeholder={label}
            type={type || 'checkbox'}
            onChange={(data) => {
              input.onChange(data)
              setTimeout(handleSubmit, 1)
            }}
          />
          {' '}
          {label}
        </label>
        {touched && error && <span>{error}</span>}
      </div>
    </div>
  )
}

renderCheckbox.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  meta: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func
}

const renderField = ({ input, label, type, handleSubmit, meta: { touched, error } }) => {
  return (
    <div className='form-group'>
      <label>{label}</label>
      <input className='form-control' {...input}
        type={type || 'text'}
        onChange={(data) => {
          input.onChange(data)
          setTimeout(handleSubmit, 1)
        }}
      />
      {touched && error && <span>{error}</span>}
    </div>
  )
}

renderField.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  meta: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func
}

const renderTextarea = ({ input, label, handleSubmit, meta: { touched, error } }) => {
  return (
    <div className='form-group'>
      <label>{label}</label>
      <textarea className='form-control' {...input}
        rows={3}
        onChange={(data) => {
          input.onChange(data)
          setTimeout(handleSubmit, 1)
        }}
      />
      {touched && error && <span>{error}</span>}
    </div>
  )
}

renderTextarea.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  // type: PropTypes.string,
  meta: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func
}

const renderRadio = ({ input, label, type, handleSubmit, meta: { touched, error } }) => {
  return (
    <label className='radio-inline'>
      <input {...input}
        // placeholder={label}
        type={type || 'text'}
        onChange={(data) => {
          input.onChange(data)
          setTimeout(handleSubmit, 1)
        }}
      />
      {label}
    </label>
  )
}

renderRadio.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  meta: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func
}

// MySelectComponent.js
const renderSelect = ({ input, handleSubmit, options, label, linkValue, isMulti, meta: { touched, error } }) => {
  return (
    <div className='form-group'>
      <label>{label}</label>
      <Select
        {...input}
        multi={isMulti}
        value={linkValue || ''}
        onBlur={() => input.onChange(linkValue || '')}
        onChange={(option) => {
          if (isMulti) {
            input.onChange(option && option.map((o) => o.value))
          } else {
            input.onChange(option && option.value)
          }

          setTimeout(handleSubmit, 1)
        }}
        options={options}
      />
    </div>
  )
}

renderSelect.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  linkValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  meta: PropTypes.object.isRequired,
  isMulti: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
}

class NodeForm extends Component {
  static propTypes = {
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    submitUpdate: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    showAttachmentChooser: PropTypes.func.isRequired,
    node: PropTypes.object.isRequired,
    didChooseAttachments: PropTypes.func.isRequired,
    links: PropTypes.array.isRequired,
    tags: PropTypes.array.isRequired,
    tagsListContexts: PropTypes.array.isRequired,
    // updateNodeBody: PropTypes.func.isRequired,
    removeSelectedNode: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    // this.setBody = this.setBody.bind(this)
    this.showAttachmentChooser = this.showAttachmentChooser.bind(this)
  }

  // componentDidMount() {
  //   console.log('componentDidMount => this.initTinyMCE()')
  //   // this.initTinyMCE()

  showAttachmentChooser (el) {
    this.props.showAttachmentChooser(el)
  }

  // removeTinyMCE() {
  //   // const e = tinyMCE.get('body')
  //   // if (e !== null) {
  //   //   e.remove()
  //   // }
  // }

  // initTinyMCE() {
  //   // console.log('==> initTinyMCE')

  //   // this.removeTinyMCE()

  //   // //
  //   // // const $redactorDe = $(this.refs.body)[0]
  //   // // $redactorDe.data('tinymce-init', true)

  //   // initTinyMCE(this.refs.body, this.setBody, this.showAttachmentChooser)
  // }

  // setBody() {
  //   const {node, updateNodeBody} = this.props
  //   const html = tinyMCE.activeEditor.getContent()
  //   updateNodeBody(node.id, html, false)
  // }

  renderImageFieldsDS () {
    const {
      handleSubmit,
      submitUpdate,
      showAttachmentChooser,
      node,
      links
    } = this.props
    return (
      <fieldset>
        <ImageForm node={node} onClickImage={showAttachmentChooser} />
        {!node.fancybox &&
          <Field
            name='link'
            label='Link'
            options={links}
            linkValue={node.link}
            component={renderSelect}
            handleSubmit={handleSubmit(submitUpdate)} />
        }
        <fieldset>
          <Field
            name='caption1'
            type='text'
            label='Caption 1'
            component={renderTextarea}
            handleSubmit={handleSubmit(submitUpdate)} />
          <Field
            name='caption2'
            type='text'
            label='Caption 2'
            component={renderTextarea}
            handleSubmit={handleSubmit(submitUpdate)} />
          <Field
            name='caption3'
            type='text'
            label='Caption 3'
            component={renderTextarea}
            handleSubmit={handleSubmit(submitUpdate)} />
          <Field
            name='darkTitle'
            type='checkbox'
            label='Dark Caption'
            component={renderCheckbox}
            handleSubmit={handleSubmit(submitUpdate)} />
        </fieldset>
        <Field
          name='title'
          type='text'
          label='Title'
          component={renderField}
          handleSubmit={handleSubmit(submitUpdate)} />
        <Field
          name='subtitle'
          type='text'
          label='Subtitle'
          component={renderField}
          handleSubmit={handleSubmit(submitUpdate)} />
        <Field
          name='videoUrl'
          type='text'
          label='Vimeo URL (zB. https://vimeo.com/151890784)'
          component={renderField}
          handleSubmit={handleSubmit(submitUpdate)} />
        {(node.videoUrl !== '' && node.videoUrl !== undefined) &&
          <Field
            name='backgroundVideo'
            type='checkbox'
            label='background video'
            component={renderCheckbox}
            handleSubmit={handleSubmit(submitUpdate)} />
        }
      </fieldset>
    )
  }

  renderImageFieldsLS () {
    const {
      handleSubmit,
      submitUpdate,
      showAttachmentChooser,
      node,
      links
    } = this.props
    return (
      <fieldset>
        <ImageForm node={node} onClickImage={showAttachmentChooser} />
        <Field
          name='fullWidth'
          type='checkbox'
          label='Full Width?'
          component={renderCheckbox}
          handleSubmit={handleSubmit(submitUpdate)} />
        <Field
          name='link'
          label='Link'
          options={links}
          linkValue={node.link}
          component={renderSelect}
          handleSubmit={handleSubmit(submitUpdate)} />
      </fieldset>
    )
  }

  renderLogoFields () {
    const {
      showAttachmentChooser,
      node,
      handleSubmit,
      submitUpdate,
    } = this.props
    return (
      <fieldset>
        <ImageForm node={node} onClickImage={showAttachmentChooser} />
        <Field
          name='right'
          type='checkbox'
          label='position at right'
          component={renderCheckbox}
          handleSubmit={handleSubmit(submitUpdate)} />
      </fieldset>
    )
  }

  renderGalleryFields () {
    const { node,
      showAttachmentChooser,
      handleSubmit,
      submitUpdate, } = this.props
    return (
      <div>
        <Field
          name='isGallery'
          type='checkbox'
          label='Display as Gallery?'
          component={renderCheckbox}
          handleSubmit={handleSubmit(submitUpdate)} />
        <GalleryForm node={node} onClickGallery={showAttachmentChooser} />
      </div>
    )
  }

  renderTextFields () {
    const {
      node,
      handleSubmit,
      submitUpdate,
    } = this.props
    return (
      <fieldset>
        <legend>Text</legend>
        <div className='form-group'>
          <RichTextArea
            name='body'
            id='node-body'
            value={node.body || ''}
            handleSubmit={handleSubmit(submitUpdate)}
          />
          Do not copy & paste Text form any Programm like MS-Word or Pages.

          {domain() === 'livingstandards' &&
            <fieldset>
              <Field
                name='hero'
                type='checkbox'
                label='Hero'
                component={renderCheckbox}
                handleSubmit={handleSubmit(submitUpdate)} />
              <Field
                name='sticky'
                type='checkbox'
                label='Sticky'
                component={renderCheckbox}
                handleSubmit={handleSubmit(submitUpdate)} />
            </fieldset>
          }
        </div>
      </fieldset>
    )
  }

  renderListFields () {
    const {
      handleSubmit,
      submitUpdate,
      node,
      tags
    } = this.props

    return (
      <fieldset>
        <legend>List Type</legend>
        <Field
          name='listType'
          type='radio'
          value='project'
          label='Project'
          component={renderRadio}
          handleSubmit={handleSubmit(submitUpdate)} />
        <Field
          name='listType'
          type='radio'
          value='page'
          label='Page'
          component={renderRadio}
          handleSubmit={handleSubmit(submitUpdate)} />
        <Field
          name='listType'
          type='radio'
          value='product'
          label='Product'
          component={renderRadio}
          handleSubmit={handleSubmit(submitUpdate)} />
        <Field
          name='listType'
          type='radio'
          value='finding'
          label='Finding'
          component={renderRadio}
          handleSubmit={handleSubmit(submitUpdate)} />
        <hr />
        <legend>Tag</legend>
        <Field
          name='tagType'
          type='radio'
          value='all'
          label='All'
          component={renderRadio}
          handleSubmit={handleSubmit(submitUpdate)} />
        <Field
          name='tagType'
          type='radio'
          value='slug'
          label='Tag from Slug'
          component={renderRadio}
          handleSubmit={handleSubmit(submitUpdate)} />

        <Field
          name='tagType'
          type='radio'
          value='mediaSlug'
          label='Media Slug'
          component={renderRadio}
          handleSubmit={handleSubmit(submitUpdate)} />
        <Field
          name='tagType'
          type='radio'
          value='tag'
          label='Tag'
          component={renderRadio}
          handleSubmit={handleSubmit(submitUpdate)} />

        <br />
        <br />
        {(node.tagType === 'tag') &&
          <Field
            name='tag'
            label='Tag'
            component={renderSelect}
            linkValue={node.tag}
            options={tags}
            type='text'
            handleSubmit={handleSubmit(submitUpdate)} />
        }
        <br />
        <Field
          name='perPage'
          label='Per Page'
          component={renderField}
          type='number'
          handleSubmit={handleSubmit(submitUpdate)} />
      </fieldset>
    )
  }

  renderProjectInfoFields () {
    const {
      node,
      handleSubmit,
      submitUpdate
    } = this.props
    return (
      <div>
        <Field
          name='date'
          type='text'
          label='Date'
          component={renderField}
          handleSubmit={handleSubmit(submitUpdate)} />
        <legend>Description</legend>
        <div className='form-group'>
          <RichTextArea
            name='body'
            id='node-body'
            value={node.body || ''}
            handleSubmit={handleSubmit(submitUpdate)}
          />
        </div>
      </div>
    )
  }

  renderProductInfoFields () {
    const {
      node,
      handleSubmit,
      submitUpdate
    } = this.props
    return (
      <div>
        <legend>Description</legend>
        <div className='form-group'>
          <RichTextArea
            name='body'
            id='node-body'
            value={node.body || ''}
            handleSubmit={handleSubmit(submitUpdate)}
          />
        </div>
      </div>
    )
  }

  renderTagListFields () {
    const {
      handleSubmit,
      submitUpdate,
      node,
      tagsListContexts,
    } = this.props
    return (
      <fieldset>
        <Field
          name='twoCols'
          type='checkbox'
          label='Two Cols'
          component={renderCheckbox}
          handleSubmit={handleSubmit(submitUpdate)} />
        <Field
          name='tagList1Context'
          label='List 1'
          options={tagsListContexts}
          linkValue={node.tagList1Context}
          component={renderSelect}
          handleSubmit={handleSubmit(submitUpdate)} />
        {
          node.twoCols &&
          <Field
            name='tagList2Context'
            label='List 2'
            options={tagsListContexts}
            linkValue={node.tagList2Context}
            component={renderSelect}
            handleSubmit={handleSubmit(submitUpdate)} />
        }
      </fieldset>
    )
  }

  renderFourColumnsFields () {
    const {
      node,
      handleSubmit,
      submitUpdate
    } = this.props
    return (
      <fieldset>
        <legend>FourColumns</legend>
        {node.cols.map((col, i) => (
          <div key={`col-${i}`}>
            <Field
              name={`cols[${i}][title]`}
              label='Title'
              component={renderField}
              handleSubmit={handleSubmit(submitUpdate)} />
            <RichTextArea
              name={`cols[${i}][items]`}
              id={`cols[${i}][items]`}
              value={col.items}
              handleSubmit={handleSubmit(submitUpdate)}
            />
          </div>
        ))}
      </fieldset>
    )
  }

  renderFields () {
    const { node } = this.props
    switch (node.nodeType) {
      case NodeTypes.Image:
        return (
          domain() === 'livingstandards'
            ? this.renderImageFieldsLS()
            : this.renderImageFieldsDS()
        )
      case NodeTypes.Logo:
        return this.renderLogoFields()
      case NodeTypes.Gallery:
        return this.renderGalleryFields()
      case NodeTypes.Text:
        return this.renderTextFields()
      case NodeTypes.List:
        return this.renderListFields()
      case NodeTypes.ProjectInfo:
      case NodeTypes.FindingInfo:
        return this.renderProjectInfoFields()
      // return this.renderProjectInfoFields()
      case NodeTypes.ProductInfo:
        return this.renderProductInfoFields()
      case NodeTypes.TagList:
        return this.renderTagListFields()
      case NodeTypes.FourColumns:
        return this.renderFourColumnsFields()

    }
  }

  renderDSDefaults () {
    const {
      handleSubmit,
      submitUpdate,
    } = this.props

    return (
      <fieldset>
        <h5>Breite</h5>
        <Field
          name='width'
          type='radio'
          value='full'
          label='full'
          component={renderRadio}
          handleSubmit={handleSubmit(submitUpdate)} />
        <Field
          name='width'
          type='radio'
          value='half'
          label='half'
          component={renderRadio}
          handleSubmit={handleSubmit(submitUpdate)} />
        <Field
          name='width'
          type='radio'
          value='quarter'
          label='quarter'
          component={renderRadio}
          handleSubmit={handleSubmit(submitUpdate)} />

        <hr />
        <Field
          name='right'
          type='checkbox'
          label='position at right'
          component={renderCheckbox}
          handleSubmit={handleSubmit(submitUpdate)} />
        <Field
          name='clear'
          type='checkbox'
          label='newline'
          component={renderCheckbox}
          handleSubmit={handleSubmit(submitUpdate)} />
        <hr />
        <Field
          name='borderBottom'
          type='checkbox'
          label='Border Bottom?'
          component={renderCheckbox}
          handleSubmit={handleSubmit(submitUpdate)} />
      </fieldset>
    )
  }

  renderLSDefaults () {
    return (
      null
    )
  }

  render () {
    const {
      handleSubmit,
      submitUpdate,
      node,
      didChooseAttachments,
      removeSelectedNode
    } = this.props
    return (
      <div>
        {node.id > 0 && <div>Anchor: <code>#n-{node.id}</code></div>}
        <AttachmentChooser
          modalId={modalId}
          objId={objId(node)}
          didComplete={didChooseAttachments} />
        <form
          onSubmit={handleSubmit(submitUpdate)}
        >
          <input type='hidden' name='utf-8' value='✓' />
          <div className='form-body'>
            <div className='row'>
              <button type='button' className='btn btn-danger btn-sm pull-right' onClick={removeSelectedNode}>
                <i className='fa fa-trash-o' />
                {' '}
                Delete
              </button>
            </div>
            <div>
              {this.renderFields()}
              {domain() === 'livingstandards'
                ? this.renderLSDefaults()
                : this.renderDSDefaults()}
            </div>
          </div>
          <div className='form-footer'>

            <Field name='id' type='hidden' component='input' />
          </div>
        </form>
      </div>
    )
  }
}

const mapStateToProps = createSelector(
  selectSelectedNode(),
  selectLinks(),
  makeSelectTags(),
  (node, links, tags) => ({
    node,
    links,
    tags: tags.map((t) => ({ label: t.name, value: t.name })),
    initialValues: node,
    enableReinitialize: true,
    tagsListContexts: ['media', 'industries', 'areas', 'tags'].map((v) => ({ label: v, value: v }))
  })
)

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    submitUpdate: (node) => dispatch(updateNode(node)),
    showAttachmentChooser: (el) => dispatch(showAttachmentChooser(modalId, el)),
    didChooseAttachments: () => dispatch(didChooseAttachments(modalId)),
    removeSelectedNode: () => dispatch(removeSelectedNode()),
    updateNodeBody: (id, body, isEng) => dispatch(updateNodeBody(id, body, isEng)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'node'
  })(NodeForm)
)
