import { createSelector } from 'reselect'

const selectPage = () => (state) => state.page

const selectPageId = () => createSelector(
  selectPage(),
  (page) => page.pageId
)

const selectWidth = () => createSelector(
  selectPage(),
  (page) => page.width
)

const selectNodes = () => createSelector(
  selectPage(),
  (page) => page.nodes
)

const selectNode = (id) => createSelector(
  selectNodes(),
  (nodes) => nodes.find((a) => a.id === id)
)

const selectSelectedId = () => createSelector(
  selectPage(),
  (state) => state.selectedId
)

const selectSelectedNode = (id) => createSelector(
  selectSelectedId(),
  selectNodes(),
  (id, nodes) => {
    if (id != null) {
      return nodes.find((a) => a.id === id)
    }
    return null
  }
)

const selectDeletedIds = () => createSelector(
  selectPage(),
  (page) => page.deletedIds
)

const selectSubmitting = () => createSelector(
  selectPage(),
  (page) => page.submitting
)

const selectErr = () => createSelector(
  selectPage(),
  (page) => page.err
)

const selectLinks = () => (state) => state.links

const selectTags = () => (state) => state.tags

export {
  selectPage,
  selectPageId,
  selectNodes,
  selectNode,
  selectSelectedId,
  selectSelectedNode,
  selectDeletedIds,
  selectLinks,
  selectTags,
  selectWidth,
  selectSubmitting,
  selectErr
}
