import { createSelector } from 'reselect'

const selectProjects = () => (state) => state.projects

const makeSelectProjects = (id) => createSelector(
  selectProjects(),
  (projects) => projects.projects
)

const makeSelectProject = (id) => createSelector(
  selectProjects(),
  (state) => state.projects.find((a) => a.id === id)
)

export {
  selectProjects,
  makeSelectProjects,
  makeSelectProject
}
