import React from 'react'
import PropTypes from "prop-types"
import classnames from 'classnames'

const ImageForm = ({
  node,
  onClickImage
}) => {
  const onClick = () => onClickImage(node)
  const className = classnames('form-group form-image-select', { 'image-present': node.thumbUrl !== undefined })
  return (
    <div className={className}>
      <a className='btn btn-default' onClick={onClick}>
        <i className='fa fa-image' />
        {' '}
        Bild wählen
      </a>
      <div>
        <img src={node.thumbUrl} onClick={onClick} />
      </div>
    </div>
  )
}

ImageForm.propTypes = {
  onClickImage: PropTypes.func.isRequired,
  node: PropTypes.object.isRequired
}

export default ImageForm
