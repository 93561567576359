import {
  TAGS_FETCH_REQUEST,
  TAGS_INIT
} from './constants'

export const fetchTags = (page = 1) => ({
  type: TAGS_FETCH_REQUEST,
  page
})

export const initTags = () => ({
  type: TAGS_INIT
})
