import {
  PROJECTS_FETCH_REQUEST,
  PROJECTS_INIT
} from './constants'

export const fetchProjects = (page = 1) => ({
  type: PROJECTS_FETCH_REQUEST,
  page
})

export const initProjects = () => ({
  type: PROJECTS_INIT
})

