import React from 'react'
import PropTypes from "prop-types"
import classnames from 'classnames'
import styles from './styles.module.scss'

const className = classnames('modal', styles.Modal)

const Modal = ({
  children,
  title,
  commit,
  divId,
  commitLabel,
  commitButtonEnbaled
}) => (
  <div className={className}
    id={divId}
    tabIndex='-1'
    role='dialog'
    aria-labelledby='modalLabel'>
    <div className='modal-dialog modal-lg' role='document'>
      <div className='modal-content'>
        <div className='modal-header'>
          <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
            <span aria-hidden='true'>&times;</span>
          </button>
          <h4 className='modal-title' id='modalLabel'>{title}</h4>
        </div>
        <div className='modal-body'>
          <div id='modal-content'>
            {children}
          </div>
        </div>
        <div className='modal-footer'>
          <button type='button' className='btn btn-default' data-dismiss='modal'>Schliessen</button>
          {
            commit &&
            <button
              disabled={!commitButtonEnbaled}
              type='button'
              className='btn btn-primary'
              data-dismiss='modal'
              onClick={commit}>
              {commitLabel || 'Bestätigen'}
            </button>
          }
        </div>
      </div>
    </div>
  </div>
)

Modal.propTypes = {
  children: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  commit: PropTypes.func,
  commitLabel: PropTypes.string,
  commitButtonEnbaled: PropTypes.bool,
  divId: PropTypes.string.isRequired
}

export default Modal

