import { eventChannel, END, buffers } from 'redux-saga'
import { put, select, call, fork, take, join, actionChannel, takeEvery, delay } from 'redux-saga/effects'
import {
  ATTACHMENTS_ADD_FILE,
  ATTACHMENTS_UPLOAD_ENQUEUED,
  ATTACHMENTS_UPLOAD_REQUEST,
  ATTACHMENTS_UPLOAD_SUCCESS,
  ATTACHMENTS_UPLOAD_FAILED,
  ATTACHMENTS_UPLOAD_CANCEL,
  ATTACHMENTS_UPLOAD_CANCELED,
  ATTACHMENTS_MAX_PARALLEL_UPLOADS,
  ATTACHMENTS_ADD_PREVIEW,
  ATTACHMENT_GENERATE_PREVIEW
} from '../constants'

import { upload, cancelSource } from '../api'
import { uploadStarted, uploadProgress, toggle } from '../actions'
import { selectAttachment } from '../selectors'

/***********************************
  Workers
 ***********************************/

let _startId = parseInt((new Date().getTime()) / 1000 * -1)
const getId = () => _startId--

// worker Saga: will be fired on ATTACHMENTS_ADD_FILE actions
function* addFiles (action) {
  // add tmpId
  const files = action.files.map((file) => {
    file.tmpId = getId()
    return file
  })
  yield* (
    files.map((file) => {
      return put({
        type: ATTACHMENTS_UPLOAD_REQUEST,
        file,
        autoSelect: action.autoSelect
      })
    })
  )


  for (let i in files) {
    yield put({
      type: ATTACHMENT_GENERATE_PREVIEW,
      file: files[i],
    })
  }
}

const isImage = (fn) => /\.(jpg|jpeg|gif|png)$/ig.test(fn)

function* generatePreviewsWorker () {
  // 1- Create a channel for request actions
  const requestChan = yield actionChannel(ATTACHMENT_GENERATE_PREVIEW, buffers.expanding())
  while (true) {
    yield delay(100)
    const { file } = yield take(requestChan)

    try {
      if (isImage(file.name)) {
        const thumbUrl = yield call(window.URL.createObjectURL, file)
        yield put({
          type: ATTACHMENTS_ADD_PREVIEW,
          attachment: {
            id: file.tmpId,
            thumbUrl
          }
        })
      }
    } catch (e) {
      //
    }
  }
}

function* _cancelUpload (action) {
  // Select attachment from store
  const attachment = yield select(selectAttachment(action.id))
  // cancel the axios upload
  try {
    if (typeof attachment.cancel === 'function') {
      attachment.cancel()
      // cancel func only exists in running uploads
      // fire ATTACHMENTS_UPLOAD_CANCELED
      yield put({ type: ATTACHMENTS_UPLOAD_CANCELED, id: action.id })
    }
  } catch (e) {
    console.error('attachment.cancel', e)
  }
}

/******************
  Upload
 ******************/

const progressChannel = (id, cancel) => {
  let _emitter
  const chan = eventChannel(emitter => {
    _emitter = emitter
    return () => cancel
  })

  return {
    chan,
    cb: (progress) => {
      if (progress < 1) {
        _emitter({ id, progress })
      } else {
        _emitter(END)
      }
    }
  }
}

function* progressRepporter (id, chan) {
  try {
    // progress
    while (true) {
      // get progress
      let { id, progress } = yield take(chan)
      // put progress
      yield put(uploadProgress(id, progress))
    }

  } catch (e) {
    console.log(e);
    debugger
  }
}


const _cancellations = {}

function* _uploadAttachment (file, autoSelect) {
  let task
  const id = file.tmpId
  try {
    // axios cancel source
    const source = cancelSource()


    // fire  uploadStarted action add cancel func
    yield put(uploadStarted(id, source.cancel))

    // progress event channel
    const { chan, cb } = yield call(progressChannel, id, source.cancel)

    // fork api upload so we can continue execution
    task = yield fork(upload, file, source.token, cb)

    // fork progressRepporter, continue wait for task to finish
    yield fork(progressRepporter, id, chan)

    // wait for upload task to process response
    const resp = yield join(task)
    // make sure we always send progress = 1 (100 %)
    yield put(uploadProgress(id, 1))

    if (autoSelect) {
      yield put(toggle(id))
    }

    // fire sucess
    yield put({
      type: ATTACHMENTS_UPLOAD_SUCCESS,
      tmpId: id,
      attachment: { ...resp.data.attachment, cancel: null, progress: 1 }
    })
  } catch (e) {
    debugger
    // put failed
    yield put({ type: ATTACHMENTS_UPLOAD_FAILED, message: e.message })
  }
}

// function* uploadSerial() {
//  // 1- Create a channel for request actions
//   const requestChan = yield actionChannel(ATTACHMENTS_UPLOAD_ENQUEUED)
//   while (true) {
//     // 2- take from the channel
//     const {file} = yield take(requestChan)
//     // 3- Note that we're using a blocking call
//     try {
//       yield call(_uploadAttachment, file)
//     } catch (e) {
//       console.log('ERR', e)
//     }
//   }
// }

let counter = 0

function* uploadLimiter () {
  // 1- Create a channel for request actions
  const requestChan = yield actionChannel(ATTACHMENTS_UPLOAD_ENQUEUED, buffers.expanding())
  // const limiterChan = yield actionChannel(
  //   [
  //     ATTACHMENTS_UPLOAD_SUCCESS,
  //     ATTACHMENTS_UPLOAD_FAILED,
  //     ATTACHMENTS_UPLOAD_CANCELED // this only occurres for running uploads
  //   ]
  // )
  const limiter = [
    ATTACHMENTS_UPLOAD_SUCCESS,
    ATTACHMENTS_UPLOAD_FAILED,
    ATTACHMENTS_UPLOAD_CANCELED // this only occurres for running uploads
  ]
  while (true) {
    // 2- take from the channel
    const { file, autoSelect } = yield take(requestChan)

    // check if the attachment has been removed?
    const attachment = yield select(selectAttachment(file.tmpId))

    if (attachment === undefined) {
      continue
    }

    counter++
    yield fork(function* () {
      try {
        yield call(_uploadAttachment, file, autoSelect)
      } catch (e) {
        // console.log('ERR', e)
      }
    })

    // 4- take from limiterChan
    if (counter >= ATTACHMENTS_MAX_PARALLEL_UPLOADS) {
      yield take(limiter)
      counter--
    }
  }
}

function* uploadAttachment (action) {
  yield put({
    ...action,
    type: ATTACHMENTS_UPLOAD_ENQUEUED
  })
}

/***********************************
  Sagas
 ***********************************/

/*
  addFilesSaga

*/
export function* addFilesSaga () {
  yield takeEvery(ATTACHMENTS_ADD_FILE, addFiles)
}

/*
  cancelUploadSaga
*/
export function* cancelUploadSaga () {
  yield takeEvery(ATTACHMENTS_UPLOAD_CANCEL, _cancelUpload)
}

// serial que using actionChannel
export function* generatePreviewsSaga () {
  yield fork(generatePreviewsWorker)
}

export function* uploadSaga () {
  yield actionChannel(ATTACHMENT_GENERATE_PREVIEW, buffers.expanding())
  yield actionChannel(ATTACHMENTS_UPLOAD_REQUEST, buffers.expanding())
  yield actionChannel(ATTACHMENTS_UPLOAD_ENQUEUED, buffers.expanding())

  yield* [
    fork(uploadLimiter),
    takeEvery(ATTACHMENTS_UPLOAD_REQUEST, uploadAttachment)
  ]

}
