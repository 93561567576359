import React, { Component } from 'react'
import PropTypes from "prop-types"
import classnames from 'classnames'
import { useDrop } from 'react-dnd'
import { ItemTypes, NodeTypes } from '../constants'
import { addNode } from '../actions'
import Node from './Node'
// import Download from './Download'
import { empty } from '../../utils'

const renderContent = (node) => {
  switch (node.nodeType) {
    case NodeTypes.Image: {
      const className = classnames('image-node', {
        'placeholder fa fa-image': empty(node.thumbUrl)
      })
      const titleClassName = classnames('title', { dark: node.darkTitle })
      if (node.overlayTitle) {
        return (
          <div className={className}>
            <div className={titleClassName}>
              <h1>{node.titleDe}</h1>
              <h2>{node.subtitleDe}</h2>
            </div>
            <img src={String(node.thumbUrl).replace('thumb', 'large')} />
          </div>
        )
      } else {
        return (
          <div className={className}>
            <img src={String(node.thumbUrl).replace('thumb', 'large')} />
            <h1>{node.titleDe}</h1>
            <h2>{node.subtitleDe}</h2>
            <div dangerouslySetInnerHTML={{ __html: node.bodyDe }} />
          </div>
        )
      }
    }
    case NodeTypes.Gallery: {
      const className = classnames('gallery-node', {
        'placeholder fa fa-th-large': node.attachments.length === 0
      })
      return (
        <div className={className}>
          {node.attachments.map((a, i) => {
            return (
              <img
                src={String(a.thumbUrl)}
                key={i}
                className="gallery-image"
              />
            )
          })}
        </div>
      )
    }
    case NodeTypes.Text:
      const className = classnames('text-node', {
        'placeholder fa fa-font': empty(node.bodyDe)
      })
      if (!empty(node.color)) {
        return (
          <div
            style={{
              color: node.color,
              backgroundColor: node.bgColor,
              padding: '.8em'
            }}
            className={className}
            dangerouslySetInnerHTML={{ __html: node.bodyDe }}
          />
        )
      }
      return <div className={className} dangerouslySetInnerHTML={{ __html: node.bodyDe }} />
    case NodeTypes.Form: {
      const className = classnames('form-node', 'placeholder', 'fa fa-list')
      return (
        <div className={className}>
          <span>{node.formType}</span>
        </div>
      )
    }
    // case NodeTypes.Download: {
    //   const className = classnames('download-node', 'placeholder', 'fa fa-download')
    //   return (
    //     <div className={className}>
    //       {node.download && (
    //         <div>
    //           <img src={node.download.previewUrl} />
    //           <h4>{node.download.titleDe}</h4>
    //           <div>{node.download.fileName}</div>
    //           <div>{node.download.fileSize}</div>
    //         </div>
    //       )}
    //     </div>
    //   )
    // }
  }
}

const Page = ({ isOver, nodes, moveNode, selectedId, toggleNode }) => {
  const className = classnames('page', { isOver: isOver })
  const [{ }, drop] = useDrop(() => ({
    accept: ItemTypes.NODE,
    drop: () => ({ name: 'Page' }),
    collect: (monitor) => ({
      isOver: monitor.isOver()
    }),
    hover: (item, monitor) => { }
  }))

  return (
    <div className={className} ref={drop}>
      {
        nodes.map((node, i) => {
          return (
            <Node
              key={node.id}
              index={i}
              moveNode={moveNode}
              selected={node.id === selectedId}
              onClickNode={toggleNode}
              isDragging={false}
              node={node}
              id={node.id}
            >
              {renderContent(node)}
            </Node>
          )
        })
      }
    </div>
  )
}

Page.propTypes = {

  // connectDropTarget: PropTypes.func.isRequired,
  isOver: PropTypes.bool,
  nodes: PropTypes.arrayOf(PropTypes.object).isRequired,
  moveNode: PropTypes.func.isRequired,
  selectedId: PropTypes.number.isRequired,
  toggleNode: PropTypes.func.isRequired

}
export default Page
