import {
  combineReducers,
  createStore,
  applyMiddleware,
  compose
} from 'redux'
// import thunk from 'redux-thunk'
import { createLogger } from 'redux-logger'
import { reducer as formReducer } from 'redux-form'
import createSagaMiddleware from 'redux-saga'

import pageReducer from './reducer'
import linksReducer from './linksReducer'
import attachmentsReducer from '../Attachments/reducer'
import { initPage, initLinks } from './actions'

import sagas from './sagas'
import attachmentsSagas from '../Attachments/sagas'

import projects from '../Projects/reducer'
import projectSagas from '../Projects/sagas'
import { initProjects } from '../Projects/actions'

import tags from '../Tags/reducer'
import tagsSagas from '../Tags/sagas'
import { initTags, fetchTags } from '../Tags/actions'

const logger = createLogger({
  level: 'info',
  collapsed: true,
})

const rootReducer = combineReducers({
  page: pageReducer,
  links: linksReducer,
  attachments: attachmentsReducer,
  form: formReducer,
  projects,
  tags
})

// create the saga middleware
const sagaMiddleware = createSagaMiddleware()

const enhancer = compose(
  process.env.NODE_ENV === 'development'
    ? applyMiddleware(logger, sagaMiddleware)
    : applyMiddleware(sagaMiddleware),
  window.devToolsExtension ? window.devToolsExtension() : noop => noop
)

export default function initStore (initData, railsContext) {
  const {
    pageId,
    title,
    nodes,
    links,
  } = initData

  let state = rootReducer(undefined, initPage(pageId, title, nodes))
  state = rootReducer(state, initLinks(links))
  state = rootReducer(state, initTags())
  state = rootReducer(state, initProjects())
  const store = createStore(rootReducer, state, enhancer)

  // run all default exported sagas
  sagas.map(sagaMiddleware.run)
  attachmentsSagas.map(sagaMiddleware.run)
  projectSagas.map(sagaMiddleware.run)
  tagsSagas.map(sagaMiddleware.run)

  // load tags
  store.dispatch(fetchTags())

  if (module.hot) {
    module.hot.accept('./reducer', () =>
      store.replaceReducer(require('./reducer')) // eslint-disable-line global-require
    )
  }
  return store
}
