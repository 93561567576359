import {
  PROJECTS_FETCH_REQUEST,
  PROJECTS_FETCH_SUCCESS,
  PROJECTS_FETCH_FAILED,
} from './constants'

import {setPropArray} from '../utils'

const initialState = {
  complete: false,
  submitting: false,
  err: null,
  usage: {},
  page: 1,
  total: 0,
  perPage: 1000,
  q: {},
  projects: []
}

function reducer (state = initialState, action) {
  switch (action.type) {
    case PROJECTS_FETCH_REQUEST:
      return {
        ...state,
        page: action.page,
        q: action.q,
        projects: setPropArray(state.projects, 'dirty', true),
        complete: false,
        submitting: true,
      }
    case PROJECTS_FETCH_SUCCESS:
      return {
        ...state,
        projects: state.projects.concat(action.projects).filter((a) => a.dirty !== true),
        complete: true,
        submitting: false,
        total: action.total,
        perPage: action.perPage,
        err: null,
      }
    case PROJECTS_FETCH_FAILED:
      return {
        ...state,
        complete: false,
        submitting: false,
        err: action.err
      }
    default:
      return state
  }
}

export default reducer
