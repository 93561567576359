import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classnames from 'classnames'
import { HTML5Backend } from "react-dnd-html5-backend";
// import { DragDropContext } from 'react-dnd'
import { useDrag, DndProvider } from "react-dnd";
// import Frame from 'react-frame-component'
import { domain } from '../../utils'
import PageBuilder from '../components/PageBuilder'
import { NodeTypes } from '../constants'
import PageContainer from '../containers/PageContainer'
import NodeForm from '../containers/NodeForm'
import { createSelector } from 'reselect'
import {
  selectSelectedNode,
  selectWidth,
  selectSubmitting,
  selectErr,
} from '../selectors'
import { save, addNode, changeWidth, removeSelectedNode } from '../actions'

// @DragDropContext(HTML5Backend)
class App extends Component {
  static propTypes = {
    ownerType: PropTypes.string,
    save: PropTypes.func.isRequired,
    selectedNode: PropTypes.object,
    addImage: PropTypes.func.isRequired,
    addLogo: PropTypes.func.isRequired,
    addGallery: PropTypes.func.isRequired,
    addText: PropTypes.func.isRequired,
    addList: PropTypes.func.isRequired,
    addProjectInfo: PropTypes.func.isRequired,
    addFindingInfo: PropTypes.func.isRequired,
    addProductInfo: PropTypes.func.isRequired,
    removeSelectedNode: PropTypes.func.isRequired,
    addTagList: PropTypes.func.isRequired,
    addFourColumns: PropTypes.func.isRequired,
    width: PropTypes.number.isRequired,
    incWidth: PropTypes.func.isRequired,
    decWidth: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    err: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.keyHandler = this.keyHandler.bind(this)
  }

  componentDidMount () {
    // wee need to set the height after we get pageWrap ref
    setTimeout(() => this.forceUpdate(), 1)

    $(document).keydown(this.keyHandler)
  }

  componentWillUnmount () {
    $(document).off('keydown', this.keyHandler)
  }

  keyHandler (e) {
    switch (e.keyCode) {
      case 46:
        // case 8:
        this.props.removeSelectedNode()
        break
      case 187:
        this.props.incWidth()
        break
      case 189:
        this.props.decWidth()
        break

    }
    // console.log('key code', e.keyCode)
  }

  renderDS () {
    const {
      addImage,
      addGallery,
      addText,
      addList,
      addProjectInfo,
      addProductInfo,
      addFindingInfo,
      addTagList,
      addFourColumns,
      ownerType,
    } = this.props
    return (
      <div className='btn-group'>
        <a className='btn btn-default' onClick={addImage}>
          <i className='fa fa-image' />
          &nbsp;
          Image
        </a>
        <a className='btn btn-default' onClick={addGallery}>
          <i className='fa fa-th-large' />
          &nbsp;
          Image List
        </a>
        <a className='btn btn-default' onClick={addText}>
          <i className='fa fa-font' />
          &nbsp;
          Text
        </a>
        <a className='btn btn-default' onClick={addList}>
          <i className='fa fa-list' />
          &nbsp;
          List
        </a>
        <a className='btn btn-default' onClick={addFourColumns}>
          <i className='fa fa-columns' />
          &nbsp;
          FourColumns
        </a>

        <a className='btn btn-default' onClick={addProjectInfo}>
          <i className='fa fa-info' />
          &nbsp;
          Project Info
        </a>

        {ownerType === 'Finding' &&
          <a className='btn btn-default' onClick={addFindingInfo}>
            <i className='fa fa-info' />
            &nbsp;
            Finding Info
          </a>
        }
        {ownerType === 'Product' &&
          <a className='btn btn-default' onClick={addProductInfo}>
            <i className='fa fa-eur' />
            &nbsp;
            Product Info
          </a>
        }
        {ownerType === '' &&
          <a className='btn btn-default' onClick={addTagList}>
            <i className='fa fa-tags' />
            &nbsp;
            Tags
          </a>
        }
      </div>
    )
  }

  renderLS () {
    const {
      addImage,
      addLogo,
      addText,
    } = this.props
    return (
      <div className='btn-group'>
        <a className='btn btn-default' onClick={addImage}>
          <i className='fa fa-image' />
          &nbsp;
          Image
        </a>
        <a className='btn btn-default' onClick={addLogo}>
          <i className='fa fa-image' />
          &nbsp;
          Logo
        </a>
        <a className='btn btn-default' onClick={addText}>
          <i className='fa fa-font' />
          &nbsp;
          Text
        </a>
      </div>
    )
  }

  render () {
    const {
      selectedNode,
      width,
      save,
      incWidth,
      decWidth,
      submitting,
      err
    } = this.props

    const height = $(window).height() - (
      this.refs.pageWrap
        ? $(this.refs.pageWrap).offset().top
        : 220
    )

    const clsn = classnames('page-builder-items', `width-${width}`)

    return (
      <DndProvider backend={HTML5Backend}>
        <PageBuilder>
          {err &&
            <div className='error-message'>
              {err.message}
            </div>
          }
          <div className='page-builder-toolbar node-add-buttons'>
            {
              domain() === 'livingstandards'
                ? this.renderLS()
                : this.renderDS()
            }
            <div className='btn-group'>
              <a className='btn btn-default' onClick={decWidth} disabled={width === 20}>
                <i className='fa fa-search-minus' />
              </a>
              <a className='btn btn-default' onClick={incWidth} disabled={width === 100}>
                <i className='fa fa-search-plus' />
              </a>
            </div>

            <a className='btn btn-success' onClick={save} disabled={submitting}>
              Save
            </a>
            {submitting && <i className='page-builder-saving fa fa-refresh fa-spin' />}
          </div>

          <div className='page-builder-editor' ref='pageWrap'>
            <div className={clsn} style={{ width: `${width}%`, height: height }}>
              <PageContainer />
            </div>
            <div className='page-builder-sidebar'>
              {selectedNode && <NodeForm />}
            </div>
          </div>
        </PageBuilder>
      </DndProvider>
    )
  }
}

const mapStateToProps = createSelector(
  selectSelectedNode(),
  selectWidth(),
  selectSubmitting(),
  selectErr(),
  (selectedNode, width, submitting, err) => ({ selectedNode, width, submitting, err })
)

const mapDispatchToProps = (dispatch) => ({
  save: () => dispatch(save()),
  addImage: () => dispatch(addNode(NodeTypes.Image)),
  addLogo: () => dispatch(addNode(NodeTypes.Logo)),
  addGallery: () => dispatch(addNode(NodeTypes.Gallery)),
  addText: () => dispatch(addNode(NodeTypes.Text)),
  addList: () => dispatch(addNode(NodeTypes.List)),
  addProjectInfo: () => dispatch(addNode(NodeTypes.ProjectInfo)),
  addFindingInfo: () => dispatch(addNode(NodeTypes.FindingInfo)),
  addProductInfo: () => dispatch(addNode(NodeTypes.ProductInfo)),
  addFourColumns: () => dispatch(addNode(NodeTypes.FourColumns)),
  removeSelectedNode: () => dispatch(removeSelectedNode()),
  addTagList: () => dispatch(addNode(NodeTypes.TagList)),
  incWidth: () => dispatch(changeWidth(10)),
  decWidth: () => dispatch(changeWidth(-10))
})

export default connect(mapStateToProps, mapDispatchToProps)(App)
