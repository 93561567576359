
import { put, call, takeLatest } from 'redux-saga/effects'
import {
  PROJECTS_FETCH_REQUEST,
  PROJECTS_FETCH_SUCCESS,
  PROJECTS_FETCH_FAILED
} from './constants'

import { fetchProjects } from './api'

// worker Saga: will be fired on ATTACHMENTS_FETCH_REQUESTED actions
function* fetchProjectsWorker (action) {
  try {
    const { data } = yield call(fetchProjects, action.page)
    yield put({ type: PROJECTS_FETCH_SUCCESS, ...data })
  } catch (e) {
    yield put({ type: PROJECTS_FETCH_FAILED, message: e.message })
  }
}
/*
  fetchSaga

  Does not allow concurrent fetches of attachments
*/
export function* fetchSaga () {
  yield takeLatest(PROJECTS_FETCH_REQUEST, fetchProjectsWorker)
}

export default [
  fetchSaga
]
